<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Creditos del Sistema Usuario {{ usuario.name }}</h3>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <table class="table">
                  <tbody>
                    <tr>
                      <td><strong>Creditos Billetera</strong></td>
                      <td>{{ creditosConversorMoneda(creditos_billetera) }}</td>
                      <td>
                        <CButton
                          variant="outline"
                          color="info"
                          shape="pill"
                          @click="openModalCreditos('billetera')"
                          >Asignar Creditos</CButton
                        >
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Creditos SMS</strong></td>
                      <td>{{ creditosConversorMoneda(creditos_sms) }}</td>
                      <td>
                        <CButton
                          variant="outline"
                          color="info"
                          shape="pill"
                          @click="openModalCreditos('sms')"
                          >Asignar Creditos</CButton
                        >
                        <CButton
                          variant="outline"
                          color="info"
                          shape="pill"
                          @click="openModalCreditos('prueba_sms')"
                          >Asignar Creditos de Prueba</CButton
                        >
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Creditos Mail</strong></td>
                      <td>{{ creditosConversorMoneda(creditos_mail) }}</td>
                      <td>
                        <CButton
                          variant="outline"
                          color="info"
                          shape="pill"
                          @click="openModalCreditos('mail')"
                          >Asignar Creditos</CButton
                        >
                        <!--<CButton
                          variant="outline"
                          color="info"
                          shape="pill"
                          @click="openModalCreditos('prueba_sms')"
                          >Asignar Creditos de Prueba</CButton
                        >-->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <h3>Historial de Recargas</h3>
          </CCardHeader>
          <template>
            <CRow>
              <CCol col="3" class="ml-3 mt-1">
                <CSelect
                  label="Historial"
                  placeholder="Historial"
                  :options="[
                    { value: 'billetera', label: 'Historial Billetera' },
                    { value: 'sms', label: 'Historial SMS' },
                    { value: 'mail', label: 'Historial Mail' },
                  ]"
                  required
                  was-validated
                  @change="filtroItemsHistorial"
                  :value.sync="historial_select"
                >
                </CSelect>
              </CCol>
            </CRow>
          </template>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="10"
              :pagination="{ doubleArrows: false, align: 'center' }"
              column-filter
              @page-change="pageChange"
            >
              <template #creditos="{ item, index }">
                <td>
                  {{ creditosConversorMoneda(item.creditos) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            Recarga Efectuada Correctamente.
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
      <CModal
        :show.sync="creditos_prueba_modal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Asignar
            {{ parametros_config.creditos_prueba_sms }} Mensajes SMS de Prueba a
            el Usuario {{ usuario.name }}
          </CCol>
          <CCol col="12">
            <br />
            <CInputCheckbox
              :label="`Confirmar Asignacion de ${parametros_config.creditos_prueba_sms} Creditos`"
              :value="false"
              :checked.sync="autorizacion_check"
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Asignar Creditos de Prueba</h6>
          <CButtonClose
            @click="creditos_prueba_modal = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton @click="creditos_prueba_modal = false" color="danger"
            >Cancelar</CButton
          >
          <CButton
            color="success"
            @click="asignarCreditosPruebaFun()"
            v-if="$can('usuario.asignarsaldo')"
            :disabled="validaCheckAsignarCreditos"
            >Aceptar</CButton
          >
        </template>
      </CModal>
      <CModal
        :show.sync="creditos_modal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="info"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Asignar Creditos de SMS a el Usuario
            {{ usuario.name }}
          </CCol>
          <CCol col="12">
            <CInput
              label="Creditos Asignar"
              placeholder="Creditos Asignar"
              autocomplete="celular"
              type="text"
              min="1"
              minlength="1"
              tabindex="0"
              id="uid-ip8fkjcnasjl-recarga2"
              required
              was-validated
              v-model.lazy="asignarCreditos.saldo"
              v-money="money"
              @keyup="validaCantidadMaxima"
            >
            </CInput>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Interna</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_interna"
              />
            </div>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Usuario Final</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_final"
              />
            </div>
          </CCol>
          <CCol col="12">
            <CInputCheckbox
              label="Confirmar Asignacion de Creditos"
              :value="false"
              :checked.sync="autorizacion_check"
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Asignar Creditos SMS</h6>
          <CButtonClose @click="creditos_modal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="creditos_modal = false" color="danger"
            >Cancelar</CButton
          >
          <CButton
            @click="asignarCreditosFun($event, 'sms')"
            color="success"
            v-if="$can('usuario.asignarcreditos')"
            :disabled="validaCheckAsignarCreditos"
            >Aceptar</CButton
          >
        </template>
      </CModal>
      <CModal
        :show.sync="creditos_billetera_modal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Asignar Creditos Billetera"
        size="lg"
        color="success"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Asignar Creditos a la Billetera del Usuario
            {{ usuario.name }}
          </CCol>
          <CCol col="12">
            <CInput
              label="Creditos Asignar"
              placeholder="Creditos Asignar"
              autocomplete="off"
              minlength="1"
              type="text"
              min="1"
              id="uid-ip8fkjcnasjl-recarga1"
              tabindex="0"
              required
              was-validated
              v-model.lazy="asignarCreditos.saldo_billetera"
              v-money="money"
              @keyup="validaCantidadMaxima"
            >
            </CInput>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Interna</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_interna"
              />
            </div>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Usuario Final</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_final"
              />
            </div>
          </CCol>
          <CCol col="12">
            <CInputCheckbox
              label="Confirmar Asignacion de Creditos"
              :value="false"
              :checked.sync="autorizacion_check"
              required
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Asignar Creditos Billetera</h6>
          <CButtonClose
            @click="creditos_billetera_modal = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton @click="creditos_billetera_modal = false" color="danger"
            >Cancelar</CButton
          >
          <CButton
            @click="asignarCreditosBilletera"
            color="success"
            v-if="$can('usuario.asignarbilletera')"
            :disabled="validaCheckAsignarCreditos"
            >Aceptar</CButton
          >
        </template>
      </CModal>
      <CModal
        :show.sync="creditos_modal_mail"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="info"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Asignar Creditos de Mail a el Usuario
            {{ usuario.name }}
          </CCol>
          <CCol col="12">
            <CInput
              label="Creditos Asignar"
              placeholder="Creditos Asignar"
              autocomplete="celular"
              type="text"
              min="1"
              minlength="1"
              tabindex="0"
              id="uid-ip8fkjcnasjl-recarga3"
              required
              was-validated
              v-model.lazy="asignarCreditos.saldo_mail"
              v-money="money"
              @keyup="validaCantidadMaxima"
            >
            </CInput>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Interna</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_interna"
              />
            </div>
          </CCol>
          <CCol col="12">
            <div class="form-group">
              <label for="">Detalle de la Asignacion Usuario Final</label>
              <textarea
                required
                class="form-control"
                v-model="asignarCreditos.description_final"
              />
            </div>
          </CCol>
          <CCol col="12">
            <CInputCheckbox
              label="Confirmar Asignacion de Creditos"
              :value="false"
              :checked.sync="autorizacion_check"
            />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Asignar Creditos Mail</h6>
          <CButtonClose
            @click="creditos_modal_mail = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton @click="creditos_modal_mail = false" color="danger"
            >Cancelar</CButton
          >
          <CButton
            @click="asignarCreditosFun($event, 'mail')"
            color="success"
            v-if="$can('usuario.asignarcreditos')"
            :disabled="validaCheckAsignarCreditos"
            >Aceptar</CButton
          >
        </template>
      </CModal>
    </div>
  </CContainer>
</template>

<script>
import { VMoney } from "v-money";
export default {
  components: { VMoney },
  name: "RecargasUsuarios",
  data() {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      historial_select: "sms",
      items: [],
      items_filter: [],
      fields: [
        { key: "fecha_creacion", label: "Fecha", _classes: "font-weight-bold" },
        { key: "creditos", label: "Creditos" },
        { key: "descripcion_interna", label: "Descripcion Interna" },
        { key: "descripcion", label: "Descripcion Usuario Final" },
      ],
      activePage: 1,
      creditos_prueba_modal: false,
      creditos_modal: false,
      creditos_billetera_modal: false,
      creditos_modal_mail: false,
      usuario: {
        name: "",
      },
      asignarCreditos: {
        saldo: 0.0,
        saldo_billetera: 0.0,
        saldo_mail: 0.0,
        saldo_prueba: 50.0,
        description_final: "",
        description_interna: "",
      },
      creditos_sms: 0.0,
      creditos_mail: 0.0,
      creditos_billetera: 0.0,
      autorizacion_check: false,
      parametros_config: {
        creditos_prueba_sms: 0,
        max_creditos_recarga: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/roles" });
    },
    obtenerUsuarioHistorialPorId() {
      const id = this.$route.params.id;
      const $self = this;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/usuarios/recargas/" + id)
        .then((response) => {
          this.items_filter = response.data.historial;
          this.usuario = response.data.usuarios;
          this.creditos_sms = response.data.creditos.sms;
          this.creditos_billetera = response.data.creditos.billetera;
          this.creditos_mail = response.data.creditos.mail;
          $self.filtroItemsHistorial();
          this.asignarCreditos.saldo = 0.0;
          this.asignarCreditos.saldo_mail = 0.0;
          this.asignarCreditos.saldo_billetera = 0.0;
          this.asignarCreditos.description_final = "";
          this.asignarCreditos.description_interna = "";
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    getParametrosConfiguracion() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/parametros/configuracion", {
          parametros: ["creditos_prueba_sms", "max_creditos_recarga"],
        })
        .then((response) => {
          this.parametros_config = response.data;
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    asignarCreditosFun: function (evt, type = "sms") {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      const id = this.$route.params.id;
      let valida = this.validaFormSaldo();
      if (valida == false) {
        evt.target.disabled = false;
        return false;
      }
      let saldo =
        type == "mail"
          ? this.asignarCreditos.saldo_mail
          : this.asignarCreditos.saldo;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/usuarios/recargas/" + id, {
          creditos: saldo,
          description_interna: this.asignarCreditos.description_interna,
          description_final: this.asignarCreditos.description_final,
          tipo_recarga: type,
          saldo_prueba: 0,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            evt.target.disabled = false;
            this.creditos_modal = false;
            this.creditos_modal_mail = false;
            this.mostrarNotificacion++;
            this.obtenerUsuarioHistorialPorId();
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                mensajeError += `${element.mensaje} \n`;
                /*for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }*/
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      evt.target.disabled = false;
    },
    asignarCreditosPruebaFun: function (evt) {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      const id = this.$route.params.id;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/usuarios/recargas/" + id, {
          creditos: this.parametros_config.creditos_prueba_sms,
          description_interna: this.asignarCreditos.description_interna,
          description_final: this.asignarCreditos.description_final,
          tipo_recarga: "sms",
          saldo_prueba: 1,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            evt.target.disabled = false;
            this.creditos_prueba_modal = false;
            this.mostrarNotificacion++;
            this.obtenerUsuarioHistorialPorId();
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                mensajeError += `${element.mensaje} \n`;
                /*for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }*/
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    asignarCreditosBilletera: function (evt) {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      const id = this.$route.params.id;
      let valida = this.validaFormSaldo();
      if (valida == false) {
        evt.target.disabled = false;
        return false;
      }
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/usuarios/recargas/" + id, {
          creditos: this.asignarCreditos.saldo_billetera,
          saldo_prueba: 0,
          description_interna: this.asignarCreditos.description_interna,
          description_final: this.asignarCreditos.description_final,
          tipo_recarga: "billetera",
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            evt.target.disabled = false;
            this.creditos_billetera_modal = false;
            this.mostrarNotificacion++;
            this.obtenerUsuarioHistorialPorId();
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                mensajeError += `${element.mensaje} \n`;
                /*for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }*/
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    filtroItemsHistorial() {
      this.items = this.items_filter.filter(
        (p) => p.tipo_historial == this.historial_select
      );
      this.items.map((item, id) => {
        if (id % 2 == 0) {
          item["_classes"] = "table-now";
        }
        return item;
      });

      if (this.historial_select == "billetera") {
        this.fields = [
          {
            key: "fecha_creacion",
            label: "Fecha",
            _classes: "font-weight-bold",
          },
          { key: "creditos", label: "Saldo" },
          { key: "descripcion_interna", label: "Descripcion Interna" },
          { key: "descripcion", label: "Descripcion Usuario Final" },
        ];
      } else {
        this.fields = [
          {
            key: "fecha_creacion",
            label: "Fecha",
            _classes: "font-weight-bold",
          },
          { key: "creditos", label: "Creditos" },
          { key: "descripcion_interna", label: "Descripcion Interna" },
          { key: "descripcion", label: "Descripcion Usuario Final" },
        ];
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    openModalCreditos(tipo) {
      this.asignarCreditos.saldo = null;
      this.asignarCreditos.saldo_billetera = null;
      this.asignarCreditos.saldo_prueba = 0.0;
      this.asignarCreditos.description_final = "";
      this.asignarCreditos.description_interna = "";
      this.autorizacion_check = false;
      document.getElementById("uid-ip8fkjcnasjl-recarga1").value = 0;
      document.getElementById("uid-ip8fkjcnasjl-recarga2").value = 0;
      document.getElementById("uid-ip8fkjcnasjl-recarga3").value = 0;
      if (tipo == "billetera") {
        this.creditos_billetera_modal = true;
      } else if (tipo == "sms") {
        this.creditos_modal = true;
      } else if (tipo == "prueba_sms") {
        this.creditos_prueba_modal = true;
      } else if (tipo == "mail") {
        this.creditos_modal_mail = true;
      }
    },
    creditosConversorMoneda(value) {
      const formatterDolar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatterDolar.format(value).replace("$", "");
    },
    validaFormSaldo() {
      if (
        this.asignarCreditos.description_interna.trim() == "" ||
        this.asignarCreditos.description_final.trim() == "" ||
        (this.validaTieneSaldoInput(this.asignarCreditos.saldo) == 0 &&
          this.validaTieneSaldoInput(this.asignarCreditos.saldo_billetera) ==
            0.0 &&
          this.validaTieneSaldoInput(this.asignarCreditos.saldo_mail) == 0.0)
      ) {
        this.mostrarNotificacionAlerta++;
        this.autorizacion_check = false;
        return false;
      } else {
        return true;
      }
    },
    validaCantidadMaxima(event) {
      let texto = event.target.value;
      let maximo = this.creditosConversorMoneda(
        this.parametros_config.max_creditos_recarga
      );
      maximo = maximo.replaceAll(",", "");
      maximo = maximo.replaceAll(".", ",");
      texto = texto.replaceAll(".", "");
      if (parseInt(texto) > parseInt(maximo)) {
        event.target.value = "0,00";
      }
    },
    validaTieneSaldoInput(credi) {
      let texto = credi;
      texto = texto.replaceAll(".", "");
      return parseInt(texto);
    },
  },
  mounted() {
    setTimeout(() => {
      this.obtenerUsuarioHistorialPorId();
      this.getParametrosConfiguracion();
    }, 100);
  },
  computed: {
    validaCheckAsignarCreditos() {
      return this.autorizacion_check == false ? true : false;
    },
  },
};
</script>
